import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Stream} from "./stream.interface";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class StreamService {
  private baseUrl = environment.api + "/Streams";
  private cache = {};

  constructor(private httpClient: HttpClient) {}

  list(feedId: string): Observable<Stream[]> {
    if (this.cache[feedId]) {
      return of(this.cache[feedId]);
    }
    return this.httpClient.get<Stream[]>(this.baseUrl + "/" + feedId).pipe(
      tap((streams: Stream[]) => {
        this.cache[feedId] = streams;
      }),
    );
  }

  refresh(streamId: string): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/Refresh/" + streamId);
  }

  create(stream: Stream): Observable<Stream> {
    return this.httpClient.post<Stream>(this.baseUrl, stream);
  }

  update(stream: Stream): Observable<Stream> {
    this.clearCacheForStream(stream.Id);
    return this.httpClient.put<Stream>(this.baseUrl, stream);
  }

  delete(streamId: string): Observable<any> {
    return this.httpClient.delete(this.baseUrl + "/" + streamId);
  }

  clearCache() {
    this.cache = {};
  }

  clearCacheForStream(streamId: string) {
    for (const feed in this.cache) {
      if (!this.cache[feed]) continue;

      const containsStream = this.cache[feed].some((x) => x.Id == streamId);
      if (containsStream) {
        this.cache[feed] = null;
      }
    }
  }

  clearCacheForSourceId(sourceId: string): string[] {
    const streamIds = [];

    // console.log("Clearing cache for source " + sourceId);

    for (const feed in this.cache) {
      if (!this.cache[feed]) continue;

      const cachedFeed = this.cache[feed];
      const containsSource = cachedFeed.some((x) =>
        x.SourceIds.some((sourceIdInObject) => sourceIdInObject == sourceId),
      );

      if (containsSource) {
        // console.log("Contains the source for feed " + feed);
        streamIds.push(...cachedFeed.map((x) => x.Id));
        this.cache[feed] = null;
      }
    }

    return streamIds;
  }
}
